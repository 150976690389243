import axios from 'axios';

const errorHandler = (error) => {
  
  console.log(message);

  return Promise.reject(error);
};

export const SERVER = `${process.env.BASE_URL || 'https://bot.preprod.youwin.gg'}/api`;

export const axiosInstance = axios.create({
  baseURL: SERVER,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.message !== "Cannot read properties of undefined (reading 'cancelToken')") {
      errorHandler(error);
    }
  },
);
