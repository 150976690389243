import React from "react";
import ModalButton from "../ModalButton/ModalButton";

import rewardCup from "../../assets/rewardCup.svg";
import gywPackReward from "../../assets/gywPackReward.svg";
import styles from "./styles.module.css";

export const RoundClearedModal = ({ isOpen, curProgress, claimRound }) => {
  return (
    <div className={isOpen ? styles.wrapper : styles.closed}>
      <div className={styles.modal}>
        <h3 className={styles.title}>Round CLEARED</h3>
        <div className={styles.content}>
          {curProgress?.prizes.map((pr, i) => (
            <div key={i} className={styles.rewardsContainer}>
              <div style={{ width: "38px", height: "38px" }}>
                <img
                  src={pr.valueId === "RP" ? rewardCup : gywPackReward}
                  alt=""
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className={styles.rewardTitle}>
                  {pr.title.split(" ")[1]}
                </span>
                <span className={styles.reward}>{pr.title.split(" ")[0]}</span>
              </div>
            </div>
          ))}
        </div>
        <div>
          <ModalButton onClick={claimRound} />
        </div>
      </div>
    </div>
  );
};
