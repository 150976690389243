import React from "react";
import styles from "./styles.module.css";

const ModalButton = ({ onClick }) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <span className={styles.text}>Claim</span>
    </button>
  );
};

export default ModalButton;
