import { combineReducers } from 'redux';

import account from './Account';
import common from './Common';
import dynamic from './Dynamic';
import limits from "./Limits";

const app = combineReducers({
  account,
  common,
  dynamic,
  limits,
});

export default app;
