import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import play from "../../assets/footer/play.svg";
import upgrade from "../../assets/footer/upgrade.svg";
import pvp from "../../assets/footer/pvp.svg";
import quest from "../../assets/footer/quest.svg";
import profile from "../../assets/footer/profile.svg";

import playActive from "../../assets/footer/playActive.svg";
import upgradeActive from "../../assets/footer/upgradeActive.svg";
import pvpActive from "../../assets/footer/pvpActive.svg";
import questActive from "../../assets/footer/questActive.svg";
import profileActive from "../../assets/footer/profileActive.svg";

import styles from "./styles.module.css";

const mapa = [
  { name: "play", icon: play, activeIcon: playActive, path: "/" },
  {
    name: "upgrade",
    icon: upgrade,
    activeIcon: upgradeActive,
    path: "/upgrade",
  },
  { name: "pvp", icon: pvp, activeIcon: pvpActive, path: "/pvp" },
  { name: "quest", icon: quest, activeIcon: questActive, path: "/quest" },
  {
    name: "profile",
    icon: profile,
    activeIcon: profileActive,
    path: "/profile",
  },
];

export const FooterNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.wrapper}>
      {mapa.map((item, i) => {
        const activeIconHandler = location.pathname === item.path;
        const navigateHandler = () =>
          activeIconHandler ? null : navigate(item.path);
        return (
          <div key={i} className={styles.content} onClick={navigateHandler}>
            <div className={styles.iconContainer}>
              <img
                src={activeIconHandler ? item.activeIcon : item.icon}
                alt=""
              />
            </div>
            <div className={styles.name}>{item.name}</div>
          </div>
        );
      })}
    </div>
  );
};
