import React from "react";
import { useNavigate } from "react-router-dom";

import arrow from "../../assets/arrow.svg";
export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        style={{ position: "absolute", left: 0, marginRight: "10px" }}
        onClick={() => navigate("/info")}
      >
        <img src={arrow} alt="" />
      </button>
      <h1 style={{ fontWeight: "700", fontSize: "30px" }}>Page Not Found 🤨</h1>
    </div>
  );
};
