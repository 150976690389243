import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

export const LearnMoreButton = () => {
  return (
    <Link to={"http://youwin.gg"}>
      <button className={styles.wrapper}>
        <span className={styles.text}>Learn More</span>
      </button>
    </Link>
  );
};
