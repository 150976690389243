import urls from "../../../utils/imageUrls";
import plus from "../../../assets/plusButton.svg";

import styles from "./styles.module.css";

const HeaderBlocks = ({ position, bonusBalance, userBulletsPerHour }) => {
  const left = position === "left";
  return (
    <div
      className={styles.wrapper}
      style={{
        padding: left ? "0 0 0 12px" : "0 15px 0 0",
        justifyContent: left ? "space-between" : "flex-end",
        gap: left ? "10px" : "19px",
        backgroundColor: "#12121e",
        borderImageSource: `linear-gradient(${
          left ? "to right, #767676, #0d0d0d" : "to left, #767676, #0d0d0d"
        })`,
        boxShadow: `${left ? "-6px" : "6px"} 10px 12px 0px #0A0A1080`,
      }}
    >
      <section
        id="first-block"
        className={styles.firstBlock}
        style={{
          paddingBottom: left && "12px",
          order: left ? 1 : 2,
          justifyContent: !left ? "center" : "flex-end",
        }}
      >
        <span
          style={{ display: !left && "none" }}
          className={styles.firstBlockTitle}
        >
          Profit per hour
        </span>
        <div
          className={styles.iconsNumsContainer}
          style={{ height: left && "27px" }}
        >
          {left && <img style={{ height: "100%" }} src={urls.bullets} alt="" />}
          <div
            className={styles.numsContainer}
            style={{
              gap: left ? "5px" : "2px",
              marginTop: left && "7px",
              flexDirection: !left && "column",
            }}
          >
            {!left && <span>{bonusBalance}</span>}
            <div
              className={styles.numbers}
              style={{
                width: left ? "40px" : "100%",
                color: !left && "#00E890",
              }}
            >
              <span style={{ margin: left ? "0 3px 0 0" : "0 3px 0px 0" }}>
                +
              </span>
              {left ? userBulletsPerHour : 0}
            </div>
            <span
              className={styles.slashHour}
              style={{ display: !left && "none" }}
            >
              / h
            </span>
          </div>
        </div>
      </section>

      <section
        id="second-block"
        className={styles.secondBlock}
        style={{
          order: left ? 2 : 1,
        }}
      >
        {left ? (
          <button className={styles.button} onClick={() => {}}>
            <img src={plus} alt="" />
          </button>
        ) : (
          <img
            style={{
              position: "absolute",
              right: "96px",
              width: "28px",
              height: "28px",
            }}
            src={urls.gyw$Coin}
            alt=""
          />
        )}
      </section>
    </div>
  );
};

export default HeaderBlocks;
