import React from "react";

import activeRoadmap from "../../../assets/activeRoadmap.svg";
import inactiveRoadmap from "../../../assets/inactiveRoadmap.svg";

import styles from "../styles.module.css";
const ListItems = ({ item }) => {
  return (
    <li className={styles.listItem}>
      <img src={item.completed ? activeRoadmap : inactiveRoadmap} alt="" />
      <span style={{ display: "inline-block", display: "flex", gap: "13px" }}>
        {item?.icon && (
          <img
            style={{ width: "28px", height: "26px" }}
            src={item?.icon}
            alt=""
          />
        )}
        {item.title}
      </span>
    </li>
  );
};

export default ListItems;
