import React from "react";

export const PlayPage = () => {
  return (
    <div
      style={{
        padding: "130px 0",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <div style={{ width: "100%", position: "absolute", top: 0 }}>
        <InfoHeader title={"Empty for now"} goBack to={"/"} />
      </div>
      <h1>Play Page</h1>
      <span style={{ color: "#696969" }}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto
        impedit at aspernatur dignissimos ipsum unde quisquam repellendus est
        modi tenetur eveniet iure accusamus, officia inventore, corrupti totam
        deserunt aperiam magnam?
      </span>
    </div>
  );
};
