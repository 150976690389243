import calendar from "../../assets/calendar.svg";

const ywCoin = "https://d208znpz6acgp4.cloudfront.net/images/yw$.svg";

export const wellcomeScreenDB = [
  { title: "Basic game", completed: true },
  { title: "Mining upgrades", completed: true },
  { title: "Earn tasks", completed: true },
  { title: "Referral", completed: true },
  { title: "LVL ratings", completed: true },
  { title: "Daily rewards", completed: true, icon: calendar },

  { title: "Token AirDrop", completed: false, icon: ywCoin },
  { title: "Squads", completed: false },
  { title: "Time limeted events", completed: false },
  { title: "PvP battles", completed: false },
];
