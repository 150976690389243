// Account
export const UPDATE_PROFILE = 'account/updateProfile';
export const LOGIN_SUCCESS = 'account/loginSuccess';
export const SILENT_LOGIN = 'account/silentLogin';
export const LOGOUT = 'account/logout';

// Common Data
export const COMMON_DATA = 'common/data';

// Drop Box Limits
export const DROP_STATS_DATA = 'limits/limits'

// Dynamic Data
export const DYNAMIC_DATA = 'dynamic/data';
