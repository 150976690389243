import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setUserData } from "../../redux/actions/Account";
import auth from "../../services/auth";

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    const initAuth = async () => {
      auth.handleAuthentication();

      if (auth.isSignedIn()) {
        auth.setAxiosInterceptors();
        dispatch(setUserData(token));
      }

      setLoading(false);
    };

    if (location?.search.indexOf("token=") > 0) {
      auth.setSession(location.search.split("token=")[1]);
      initAuth();
    } else {
      initAuth();
    }
  }, []);

  if (isLoading) {
    return null;
  }

  return children;
}

export default Auth;
