import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import info from "../../assets/info.svg";
import urls from "../../utils/imageUrls";

import styles from "./styles.module.css";
import { useSelector } from "react-redux";

export const Information = ({ bullets, gameConfig }) => {
  const { user } = useSelector(({ account }) => account);
  const navigate = useNavigate();

  const userProgress = user?.data?.progress?.length || 0;
  const userBullets = user?.data?.curBullets || 0;
  const userAmmo = gameConfig?.ammo[0]?.value || 0;

  return (
    <div className={styles.wrapper} style={{ gap: bullets ? "10px" : "30px" }}>
      <div
        className={`${bullets ? "" : styles.infoIconContainer}`}
        onClick={() => navigate("/info")}
      >
        <img
          className={styles.icon}
          src={bullets ? urls.bullets : info}
          alt=""
        />
      </div>
      <div className={styles.nums}>
        <span style={{ color: bullets && "#55F2E5" }}>
          {bullets ? userBullets : userProgress}
        </span>
        <span
          className={`${bullets ? styles.bulSecondPart : styles.secondPart}`}
        >
          {`/ ${bullets ? userAmmo : userProgress || 0}`}
        </span>
      </div>
    </div>
  );
};
