import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./styles.module.css";
import api from "../../api";

export const Level = () => {
  const { user } = useSelector(({ account }) => account);
  const [round, setRound] = useState("");

  const level = user?.data?.level;

  useEffect(() => {
    api.getLevelConfig().then((response) => {
      setRound(response?.data[0]);
    });
  }, []);

  const roundHandler = () => {
    return round?.round_to_complete === user?.data?.round
      ? "Final round"
      : `Round ${user?.data?.round}`;
  };
  return (
    <div className={styles.lvlContainer}>
      <div className={styles.text}>
        <span className={styles.spn}>Lvl</span>
        <span className={styles.spn}>{level}</span>
      </div>
      <div className={styles.subtext}>{roundHandler()}</div>
      <div className={styles.shape} />
    </div>
  );
};
