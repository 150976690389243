import urls from "../../utils/imageUrls";
import cup from "../../assets/goldcup.svg";
import dropbox from "../../assets/info-page/dropbox.svg";
import tasks from "../../assets/info-page/tasks.svg";
import referrals from "../../assets/info-page/referrals.svg";
import events from "../../assets/info-page/events.svg";
import marketplace from "../../assets/info-page/marketplace.svg";

export const data = [
  {
    path: "token-points-leaderboard",
    title: "Token points leaderboard",
    icon: cup,
    width: 23,
    height: 23,
  },
  {
    path: "bullets-and-targets",
    title: "Bullets & targets",
    icon: urls.bullets,
    width: 29,
    height: 29,
  },
  {
    path: "dropboxes",
    title: "Dropboxes",
    icon: dropbox,
    width: 26,
    height: 28,
  },
  { path: "tasks", title: "Tasks", icon: tasks, width: 26, height: 26 },
  {
    path: "referrals",
    title: "Referrals",
    icon: referrals,
    width: 25,
    height: 25,
  },
  { path: "events", title: "Events", icon: events, width: 22, height: 23 },
  {
    path: "marketplace",
    title: "Marketplace",
    icon: marketplace,
    width: 22,
    height: 22,
  },
];
