import { useEffect, useState } from "react";

import WellcomeScreen from "./components/WellcomeScreen/WellcomeScreen";
import Auth from "./utils/Auth/Auth";

import auth from "./services/auth";
import api from "./api";

import Header from "./components/Header/Header";
import MainPage from "./scenes/MainPage/MainPage";
import { Router } from "./routes/Router";

function App() {
  useEffect(() => {
    // Check if the Telegram Web App is available
    if (window.Telegram && window.Telegram.WebApp) {
      // Initialize the Telegram Web App
      window.Telegram.WebApp.ready();

      // Request to expand the app to full screen
      window.Telegram.WebApp.expand();
    }
  }, []);

  return (
    <Auth>
      <div className="layout">
        <Router />
      </div>
    </Auth>
  );
}

export default App;
