import React from "react";

import styles from "./styles.module.css";
import urls from "../../utils/imageUrls";
import ModalButton from "../ModalButton/ModalButton";

export const LevelClearedModal = ({
  isOpen,
  claimLevel,
  curProgress,
  levelConfig,
  totalTargets,
}) => {
  console.log(levelConfig, "levelConfig");
  return (
    <div className={`${isOpen ? styles.wrapper : styles.closed}`}>
      <div className={styles.modal}>
        <div className={styles.content}>
          <h3 className={styles.title}>LEVEL CLEARED</h3>

          <section className={styles.rewards}>
            <div>
              <span className={styles.rewTitle}>Lvl bonus:</span>
              <span className={styles.curBonus}>+ {levelConfig.lvl_bonus}</span>
              <span className={styles.explain}>
                ({parseInt(levelConfig.rounds_to_complete / totalTargets)}* 100)
              </span>
            </div>
            <div>
              <span className={styles.rewTitle}>Accuracy bonus:</span>
              <span className={styles.curBonus}>
                + {levelConfig.accuracy_bonus}
              </span>
              <span className={styles.explain}>
                ({parseInt(levelConfig.rounds_to_complete / totalTargets)}*
                100%)
              </span>
            </div>
            {levelConfig?.upgrate_bonuse && (
              <div>
                <span className={styles.rewTitle}>Upgrade bonus:</span>
                <span className={styles.curBonus}>
                  {levelConfig?.upgrate_bonus}
                </span>
                <span className={styles.explain}>( 100*0 )</span>
              </div>
            )}
          </section>

          <section className={styles.rewIcon}>
            <div className={styles.rewBackground} />
            <img
              style={{ width: "49px", height: "49px", zIndex: 2 }}
              src={urls.gyws_pack}
              alt=""
            />
            <span className={styles.rewAmount}>{levelConfig?.lvl_bonus}</span>
          </section>

          <section className={styles.button}>
            <ModalButton onClick={claimLevel} />
          </section>
        </div>
      </div>
    </div>
  );
};
