import React from "react";
import { useNavigate } from "react-router-dom";

import cross from "../../assets/cross.svg";
import arrow from "../../assets/arrow.svg";
import headerGoldCup from "../../assets/headerGoldCup.svg";

import styles from "./styles.module.css";

export const InfoHeader = ({ title, goBack, to }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.borders}
        style={{
          borderImageSource: "linear-gradient(to right, #767676, #0d0d0d)",
        }}
      />
      <div className={styles.iconTitleContainer}>
        <img src={headerGoldCup} alt="" />
        <span className={styles.title}>{title}</span>
      </div>
      <button
        className={styles.button}
        style={!goBack ? { right: 0 } : { left: 0 }}
        onClick={() => navigate(to)}
      >
        <img src={goBack ? arrow : cross} alt="" />
      </button>
      <div
        className={styles.borders}
        style={{
          borderImageSource: "linear-gradient(to left, #767676, #0d0d0d)",
        }}
      />
    </div>
  );
};
