/* eslint-disable no-param-reassign */
import { DROP_STATS_DATA } from "../constants/actionTypes";

const initialState = {};

const Limits = (state = initialState, { type, payload }) => {
  switch (type) {
    case DROP_STATS_DATA:
      // console.log('reducer Limits', state , payload)
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default Limits;
