import React from "react";
import { useNavigate } from "react-router-dom";

import {
  CsIsLiveNow,
  InfoHeader,
  LearnMoreButton,
  LinksButton,
  Socials,
} from "../../components";

import { data } from "./data";

import styles from "./styles.module.css";

export const InfoPage = () => {
  return (
    <div className={styles.wrapper}>
      <InfoHeader title={"How to farm tokens & skins"} to={"/"} />
      <section className={styles.buttonsSection}>
        {data.map((item, i) => (
          <LinksButton key={i} item={item} />
        ))}
      </section>

      <section className={styles.footer}>
        <Socials />
        <LearnMoreButton />
        <CsIsLiveNow />
      </section>
    </div>
  );
};
