import React, { useState } from "react";

import rollCircle from "../../assets/roll-circle/firstCircleDashes.svg";
import circle2 from "../../assets/roll-circle/circle2.svg";
import circle from "../../assets/roll-circle/staticCircle.svg";
import thickDashes from "../../assets/roll-circle/thickDashes.svg";
import tap from "../../assets/roll-circle/tap.svg";
import bang from "../../assets/roll-circle/bang.svg";

import styles from "./styles.module.css";
import { refetchProfile } from "../../redux/actions/Account";
import { useDispatch } from "react-redux";

export const RollCircle = ({
  curProgress,
  circleAmount,
  circleIndex,
  select,
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const dispatch = useDispatch();

  const severalCircles = circleAmount > 1;

  const pressHandler = () => {
    setIsPressed(true);
    select(circleIndex + 1);
    return setTimeout(() => {
      if (curProgress?.completed && !curProgress?.claimed) {
        setIsPressed(false);
      }
      setIsPressed(false);
    }, 2000);
  };

  console.log(curProgress, "curProgress?.prizes");
  return (
    <div className={severalCircles ? styles.wrapperSM : styles.wrapper}>
      <div
        className={
          severalCircles
            ? `${styles.dashedClSM} ${styles.circlesSM}`
            : `${styles.dashedCl} ${styles.circles}`
        }
      >
        <img
          style={severalCircles ? { width: "100%", height: "100%" } : null}
          src={rollCircle}
          alt=""
        />
      </div>
      <div
        className={
          severalCircles
            ? `${styles.circle2SM} ${styles.circlesSM}`
            : styles.circles
        }
        style={{ top: severalCircles ? "14px" : "110px" }}
      >
        <img
          style={severalCircles ? { width: "100%", height: "100%" } : null}
          src={circle2}
          alt=""
        />
      </div>
      <div
        className={
          severalCircles
            ? `${styles.circle3SM} ${styles.circlesSM}`
            : `${styles.circle3} ${styles.circles}`
        }
        style={{ top: severalCircles ? "13px" : "126px" }}
      >
        <img
          style={severalCircles ? { width: "100%", height: "100%" } : null}
          src={thickDashes}
          alt=""
        />
      </div>
      <div
        className={styles.circles}
        style={
          !severalCircles
            ? { top: isPressed ? "177px" : "177px" }
            : { top: isPressed ? "6px" : "6px" }
        }
      >
        {curProgress?.targets?.indexOf(circleIndex + 1) >= 0 && !isPressed ? (
          <div className={styles.rollingResult}>
            {curProgress?.prizes.filter((p) => p.targetId === circleIndex + 1)
              .length
              ? curProgress?.prizes.filter(
                  (p) => p.targetId == circleIndex + 1
                )[0].title
              : "😓"}
          </div>
        ) : (
          <button onClick={pressHandler}>
            <img
              className={severalCircles ? styles.buttonSM : styles.button}
              src={isPressed ? bang : tap}
              alt=""
            />
          </button>
        )}
      </div>
    </div>
  );
};
