const urls = {
  Pen: 'https://d208znpz6acgp4.cloudfront.net/images/pen.svg',

  star: 'https://d208znpz6acgp4.cloudfront.net/images/star.svg',
  Save: 'https://d208znpz6acgp4.cloudfront.net/images/save.svg',
  Oops: 'https://d208znpz6acgp4.cloudfront.net/images/oops.svg',

  YWcoin: 'https://d208znpz6acgp4.cloudfront.net/images/yw$.svg',
  GYWcoin: 'https://d208znpz6acgp4.cloudfront.net/images/gyw$.svg',
  KeyImg: 'https://d208znpz6acgp4.cloudfront.net/images/key.svg',
  СupImg: 'https://d208znpz6acgp4.cloudfront.net/images/cup.svg',

  Trash: 'https://d208znpz6acgp4.cloudfront.net/images/trash.svg',
  queue: 'https://d208znpz6acgp4.cloudfront.net/images/queue.svg',

  vsImg: 'https://d208znpz6acgp4.cloudfront.net/images/vsImg.webp',
  wifiImg: 'https://d208znpz6acgp4.cloudfront.net/images/wifi.svg',
  HandImg: 'https://d208znpz6acgp4.cloudfront.net/images/hand.svg',

  Search: 'https://d208znpz6acgp4.cloudfront.net/images/search.svg',
  Bucket: 'https://d208znpz6acgp4.cloudfront.net/images/bucket.svg',
  NavCup: 'https://d208znpz6acgp4.cloudfront.net/images/navCup.svg',
  Reload: 'https://d208znpz6acgp4.cloudfront.net/images/reload.svg',
  csLogo: 'https://d208znpz6acgp4.cloudfront.net/images/cslogo.svg',

  banned: 'https://d208znpz6acgp4.cloudfront.net/images/banned.webp',
  Filter: 'https://d208znpz6acgp4.cloudfront.net/images/filters.svg',
  report2: 'https://d208znpz6acgp4.cloudfront.net/images/report2.svg',
  SteamImg: 'https://d208znpz6acgp4.cloudfront.net/images/steam.svg',
  ClockImg: 'https://d208znpz6acgp4.cloudfront.net/images/clock.svg',
  LogoImage: 'https://d208znpz6acgp4.cloudfront.net/images/logo.svg',
  PairImage: 'https://d208znpz6acgp4.cloudfront.net/images/pair.svg',

  report1: 'https://d208znpz6acgp4.cloudfront.net/images/report1.svg',
  report3: 'https://d208znpz6acgp4.cloudfront.net/images/report3.svg',
  Copying: 'https://d208znpz6acgp4.cloudfront.net/images/copying.svg',
  NavUser: 'https://d208znpz6acgp4.cloudfront.net/images/navUser.svg',

  ProfilePurple:
    'https://d208znpz6acgp4.cloudfront.net/images/profile_purple.svg',
  SupportPurple:
    'https://d208znpz6acgp4.cloudfront.net/images/support_purple.svg',

  AddItem: 'https://d208znpz6acgp4.cloudfront.net/images/addItem.svg',
  BuyItem: 'https://d208znpz6acgp4.cloudfront.net/images/buyItem.svg',
  techServe: 'https://d208znpz6acgp4.cloudfront.net/images/tech.webp',

  redFlag: 'https://d208znpz6acgp4.cloudfront.net/images/red_flag.svg',
  blocked: 'https://d208znpz6acgp4.cloudfront.net/images/blocked.webp',

  MapNuke: 'https://d208znpz6acgp4.cloudfront.net/images/map_nuke.webp',
  Exchange: 'https://d208znpz6acgp4.cloudfront.net/images/exchange.svg',
  TimeBomb: 'https://d208znpz6acgp4.cloudfront.net/images/timeBomb.svg',
  InfoIcon: 'https://d208znpz6acgp4.cloudfront.net/images/infoIcon.svg',
  Approved: 'https://d208znpz6acgp4.cloudfront.net/images/approved.svg',
  Step1Image: 'https://d208znpz6acgp4.cloudfront.net/images/step1.webp',
  Step2Image: 'https://d208znpz6acgp4.cloudfront.net/images/step2.webp',
  Step3Image: 'https://d208znpz6acgp4.cloudfront.net/images/step3.webp',
  Step4Image: 'https://d208znpz6acgp4.cloudfront.net/images/step4.webp',
  Step5Image: 'https://d208znpz6acgp4.cloudfront.net/images/step5.webp',

  CasesImage: 'https://d208znpz6acgp4.cloudfront.net/images/cases.webp',
  LockFindGame: 'https://d208znpz6acgp4.cloudfront.net/images/lock.svg',
  SafetyImage: 'https://d208znpz6acgp4.cloudfront.net/images/safety.svg',
  MapTrain: 'https://d208znpz6acgp4.cloudfront.net/images/map_train.webp',
  csGoLogo: 'https://d208znpz6acgp4.cloudfront.net/images/csgo_logo.webp',
  blueArrow: 'https://d208znpz6acgp4.cloudfront.net/images/blueArrow.svg',
  TrashGrey: 'https://d208znpz6acgp4.cloudfront.net/images/trashGrey.svg',
  NavLogout: 'https://d208znpz6acgp4.cloudfront.net/images/navLogout.svg',
  LogoutPurple:
    'https://d208znpz6acgp4.cloudfront.net/images/logout_purple.svg',

  CookiesImage: 'https://d208znpz6acgp4.cloudfront.net/images/cookie.svg',
  FindGameCoins: 'https://d208znpz6acgp4.cloudfront.net/images/coins.svg',
  BackgroundImage: 'https://d208znpz6acgp4.cloudfront.net/images/bg.webp',

  gpb1x1aim: 'https://d208znpz6acgp4.cloudfront.net/images/gpb1x1aim.webp',
  zoomImage: 'https://d208znpz6acgp4.cloudfront.net/images/zoom_image.svg',
  gameTimer: 'https://d208znpz6acgp4.cloudfront.net/images/game_timer.svg',
  DiamondImage: 'https://d208znpz6acgp4.cloudfront.net/images/diamond.svg',

  NavMarket: 'https://d208znpz6acgp4.cloudfront.net/images/marketplace.svg',
  MarketPurple:
    'https://d208znpz6acgp4.cloudfront.net/images/market_purple.svg',

  itemLocked: 'https://d208znpz6acgp4.cloudfront.net/images/itemLocked.svg',
  warningRed: 'https://d208znpz6acgp4.cloudfront.net/images/warningRed.svg',
  errorCross: 'https://d208znpz6acgp4.cloudfront.net/images/errorCross.svg',
  RemoveItem: 'https://d208znpz6acgp4.cloudfront.net/images/removeItem.svg',
  infoIconGr: 'https://d208znpz6acgp4.cloudfront.net/images/infoIconGr.svg',
  roundStarG: 'https://d208znpz6acgp4.cloudfront.net/images/roundStarG.svg',
  roundStarY: 'https://d208znpz6acgp4.cloudfront.net/images/roundStarY.svg',
  Step1ImageEn: 'https://d208znpz6acgp4.cloudfront.net/images/step1en.webp',
  Step2ImageEn: 'https://d208znpz6acgp4.cloudfront.net/images/step2en.webp',
  Step5ImageEn: 'https://d208znpz6acgp4.cloudfront.net/images/step5en.webp',

  RightArrow: 'https://d208znpz6acgp4.cloudfront.net/images/right-arrow.svg',
  closeBlack: 'https://d208znpz6acgp4.cloudfront.net/images/close_black.svg',
  LeadersRank: 'https://d208znpz6acgp4.cloudfront.net/images/gold-nova.webp',
  CalendarImage: 'https://d208znpz6acgp4.cloudfront.net/images/calendar.svg',

  MapVertigo: 'https://d208znpz6acgp4.cloudfront.net/images/map_vertigo.webp',
  MapInferno: 'https://d208znpz6acgp4.cloudfront.net/images/map_inferno.webp',
  SuccessItem: 'https://d208znpz6acgp4.cloudfront.net/images/successItem.svg',

  searchBg5x5: 'https://d208znpz6acgp4.cloudfront.net/images/5x5searchBg.webp',
  aimSearchBg: 'https://d208znpz6acgp4.cloudfront.net/images/aimSearchBg.webp',
  LeaderCoins: 'https://d208znpz6acgp4.cloudfront.net/images/yw$_multiple.svg',
  HistoryImg2: 'https://d208znpz6acgp4.cloudfront.net/images/yw$_multiple.svg',
  ProgressItem: 'https://d208znpz6acgp4.cloudfront.net/images/iconProcess.svg',
  WhiteSteamImg: 'https://d208znpz6acgp4.cloudfront.net/images/whiteSteam.svg',

  calibrateBg: 'https://d208znpz6acgp4.cloudfront.net/images/calibrate_bg.webp',
  MapOverpass: 'https://d208znpz6acgp4.cloudfront.net/images/map_overpass.webp',
  csGoCounter: 'https://d208znpz6acgp4.cloudfront.net/images/csgo_counter.webp',
  PromoSuccess: 'https://d208znpz6acgp4.cloudfront.net/images/promoSuccess.svg',
  SuccessCheck: 'https://d208znpz6acgp4.cloudfront.net/images/successCheck.svg',

  PositiveVote: 'https://d208znpz6acgp4.cloudfront.net/images/positiveVote.svg',
  NegativeVote: 'https://d208znpz6acgp4.cloudfront.net/images/negativeVote.svg',

  TournamentPrize3: 'https://d208znpz6acgp4.cloudfront.net/images/prize3d.webp',
  itemUnLocked:
    'https://d208znpz6acgp4.cloudfront.net/images/itemUnlocked.webp',
  TournamentPrize1:
    'https://d208znpz6acgp4.cloudfront.net/images/prize1st.webp',
  TournamentPrize2:
    'https://d208znpz6acgp4.cloudfront.net/images/prize2nd.webp',

  MapShortDust:
    'https://d208znpz6acgp4.cloudfront.net/images/map_shortdust.webp',
  ApprovedGreen:
    'https://d208znpz6acgp4.cloudfront.net/images/approvedGreen.svg',
  checkInCircle:
    'https://d208znpz6acgp4.cloudfront.net/images/checkInCircle.svg',
  report1Active:
    'https://d208znpz6acgp4.cloudfront.net/images/report1_active.svg',
  BackgroundMainImage:
    'https://d208znpz6acgp4.cloudfront.net/images/bgmain.webp',

  HistoryImg1:
    'https://d208znpz6acgp4.cloudfront.net/images/tourn_table_grid.svg',
  gpb1x1wingman:
    'https://d208znpz6acgp4.cloudfront.net/images/gpb1x1wingman.webp',
  report2Active:
    'https://d208znpz6acgp4.cloudfront.net/images/report2_active.svg',
  report3Active:
    'https://d208znpz6acgp4.cloudfront.net/images/report3_active.svg',

  csGoTerrorist:
    'https://d208znpz6acgp4.cloudfront.net/images/csgo_terrorist.webp',
  instructionOne:
    'https://d208znpz6acgp4.cloudfront.net/images/instruction_1.webp',
  instructionTwo:
    'https://d208znpz6acgp4.cloudfront.net/images/instruction_2.webp',
  BackgroundThirdImage:
    'https://d208znpz6acgp4.cloudfront.net/images/bgthird.webp',

  LogoBlueBorder:
    'https://d208znpz6acgp4.cloudfront.net/images/logoBlueBorder.webp',
  yellowDownload:
    'https://d208znpz6acgp4.cloudfront.net/images/yellow_download.svg',

  BackgroundSecondImage:
    'https://d208znpz6acgp4.cloudfront.net/images/bgsecond.webp',

  wingmanSearchBg:
    'https://d208znpz6acgp4.cloudfront.net/images/wingmanSearchBg.webp',
  BackgroundFifthImage:
    'https://d208znpz6acgp4.cloudfront.net/images/leaders_bg.webp',

  BackgroundFourthImage:
    'https://d208znpz6acgp4.cloudfront.net/images/tournamentGag.webp',

  wingmanSearchBg2x2:
    'https://d208znpz6acgp4.cloudfront.net/images/2x2wingmanSearchBg.webp',

  rank0: 'https://d208znpz6acgp4.cloudfront.net/images/rank_0.svg',
  rank1: 'https://d208znpz6acgp4.cloudfront.net/images/rank_1.svg',
  rank2: 'https://d208znpz6acgp4.cloudfront.net/images/rank_2.svg',
  rank3: 'https://d208znpz6acgp4.cloudfront.net/images/rank_3.svg',
  rank4: 'https://d208znpz6acgp4.cloudfront.net/images/rank_4.svg',
  rank5: 'https://d208znpz6acgp4.cloudfront.net/images/rank_5.svg',
  rank6: 'https://d208znpz6acgp4.cloudfront.net/images/rank_6.svg',
  rank7: 'https://d208znpz6acgp4.cloudfront.net/images/rank_7.svg',
  rank8: 'https://d208znpz6acgp4.cloudfront.net/images/rank_8.svg',
  rank9: 'https://d208znpz6acgp4.cloudfront.net/images/rank_9.svg',
  rank10: 'https://d208znpz6acgp4.cloudfront.net/images/rank_10.svg',
  calibrationRank:
    'https://d208znpz6acgp4.cloudfront.net/images/levelUnknown.svg',

  leader1: 'https://d208znpz6acgp4.cloudfront.net/images/leader1.svg',
  leader2: 'https://d208znpz6acgp4.cloudfront.net/images/leader2.svg',
  leader3: 'https://d208znpz6acgp4.cloudfront.net/images/leader3.svg',

  level1: 'https://d208znpz6acgp4.cloudfront.net/images/level_1_4.svg',
  yw$Coin: 'https://d208znpz6acgp4.cloudfront.net/images/yw$_coin.svg',
  gyw$Coin: 'https://d208znpz6acgp4.cloudfront.net/images/gyw$_coin.svg',
  bgLeague: 'https://d208znpz6acgp4.cloudfront.net/images/bgLeague.webp',

  rankUnknown: 'https://d208znpz6acgp4.cloudfront.net/images/rank_unknown.svg',

  howStep1ru: 'https://d208znpz6acgp4.cloudfront.net/images/how_step_1_ru.webp',
  howStep2ru: 'https://d208znpz6acgp4.cloudfront.net/images/how_step_2_ru.webp',
  howStep3ru: 'https://d208znpz6acgp4.cloudfront.net/images/how_step_3_ru.webp',
  howStep4ru: 'https://d208znpz6acgp4.cloudfront.net/images/how_step_4_ru.webp',
  howStep5ru: 'https://d208znpz6acgp4.cloudfront.net/images/how_step_5_ru.webp',

  discordWhite:
    'https://d208znpz6acgp4.cloudfront.net/images/discord_white.svg',
  rankImmortal:
    'https://d208znpz6acgp4.cloudfront.net/images/rank_immortal.svg',
  discordPurple:
    'https://d208znpz6acgp4.cloudfront.net/images/discord_purple.svg',
  leagueSeason1:
    'https://d208znpz6acgp4.cloudfront.net/images/league_season_1.svg',
  rankTopImmortal:
    'https://d208znpz6acgp4.cloudfront.net/images/rank_top_immortal.svg',
  operationPending:
    'https://d208znpz6acgp4.cloudfront.net/images/operation_pending.svg',
  operationPendingWithCircle:
    'https://d208znpz6acgp4.cloudfront.net/images/operation_pending_with_circle.svg',
  tCtSide: 'https://d208znpz6acgp4.cloudfront.net/images/t_ct_side.webp',
  newTimer: 'https://d208znpz6acgp4.cloudfront.net/images/timer_new.svg',

  awpModeBg: 'https://d208znpz6acgp4.cloudfront.net/images/awp_mode_bg.webp',
  pistolModeBg:
    'https://d208znpz6acgp4.cloudfront.net/images/pistol_mode_bg.webp',
  infoTooltip: 'https://d208znpz6acgp4.cloudfront.net/images/info_tooltip.svg',

  showLessArrow:
    'https://d208znpz6acgp4.cloudfront.net/images/show_less_arrow.svg',
  brokenGlovesAlert:
    'https://d208znpz6acgp4.cloudfront.net/images/broken_gloves_alert.webp',
  brokenGlovesAlertBg:
    'https://d208znpz6acgp4.cloudfront.net/images/broken_gloves_alert_bg.webp',

  checkCircleGreen:
    'https://d208znpz6acgp4.cloudfront.net/images/check_circle_green.svg',
  checkCirclePurple:
    'https://d208znpz6acgp4.cloudfront.net/images/check_circle_purple.svg',
  crossCircle: 'https://d208znpz6acgp4.cloudfront.net/images/cross_circle.svg',
  diamondVip: 'https://d208znpz6acgp4.cloudfront.net/images/diamond_vip.svg',

  visa: 'https://d208znpz6acgp4.cloudfront.net/images/visa.webp',
  masterCard: 'https://d208znpz6acgp4.cloudfront.net/images/mastercard.webp',
  qiwi: 'https://d208znpz6acgp4.cloudfront.net/images/qiwi.webp',
  yoomoney: 'https://d208znpz6acgp4.cloudfront.net/images/yoomoney.webp',
  bronzeKey: 'https://d208znpz6acgp4.cloudfront.net/images/bronze_key.svg',
  goldKey: 'https://d208znpz6acgp4.cloudfront.net/images/gold_key.svg',
  silverKey: 'https://d208znpz6acgp4.cloudfront.net/images/silver_key.svg',

  bullets: 'https://d208znpz6acgp4.cloudfront.net/images/bullets.svg',
  dropbox: 'https://d208znpz6acgp4.cloudfront.net/images/dropbox.webp',
  randomClassified:
    'https://d208znpz6acgp4.cloudfront.net/images/random_classified.webp',
  randomConsumer:
    'https://d208znpz6acgp4.cloudfront.net/images/random_consumer.webp',
  randomCovert:
    'https://d208znpz6acgp4.cloudfront.net/images/random_covert.webp',
  randomIndustrial:
    'https://d208znpz6acgp4.cloudfront.net/images/random_industrial.webp',
  randomMilspec:
    'https://d208znpz6acgp4.cloudfront.net/images/random_milspec.webp',
  randomRestricted:
    'https://d208znpz6acgp4.cloudfront.net/images/random_restricted.webp',
  gyws_pack: 'https://d208znpz6acgp4.cloudfront.net/images/gyws_pack.webp',
  csCircle: 'https://d208znpz6acgp4.cloudfront.net/images/cs-circle.svg',

  box_question: 'https://d208znpz6acgp4.cloudfront.net/images/box_question.svg',
  keys_arrow: 'https://d208znpz6acgp4.cloudfront.net/images/keys_arrow.svg',
  yws_pack: 'https://d208znpz6acgp4.cloudfront.net/images/yws_pack.webp',
  dropboxGray: 'https://d208znpz6acgp4.cloudfront.net/images/dropbox_gray.webp',
  tournament_ticket:
    'https://d208znpz6acgp4.cloudfront.net/images/tournament_ticket.webp',

  dropbox_violet:
    'https://d208znpz6acgp4.cloudfront.net/images/dropbox_violet.webp',
  lock_gray: 'https://d208znpz6acgp4.cloudfront.net/images/lock_gray.svg',
  target_purple:
    'https://d208znpz6acgp4.cloudfront.net/images/target_purple.svg',
  vipkey_gray: 'https://d208znpz6acgp4.cloudfront.net/images/vipkey_gray.webp',
  vipkey_active:
    'https://d208znpz6acgp4.cloudfront.net/images/vipkey_active.webp',

  landingBanner1:
    'https://d208znpz6acgp4.cloudfront.net/images/landing_banner_1.webp',
  landingBanner2:
    'https://d208znpz6acgp4.cloudfront.net/images/landing_banner_2.webp',
  landingBanner3:
    'https://d208znpz6acgp4.cloudfront.net/images/landing_banner_3.webp',
  landingBanner4:
    'https://d208znpz6acgp4.cloudfront.net/images/landing_banner_4.webp',
  landingBanner5:
    'https://d208znpz6acgp4.cloudfront.net/images/landing_banner_5.webp',
  landingPicture:
    'https://d208znpz6acgp4.cloudfront.net/images/landing_picture.webp',

  landingBgMobile:
    'https://d208znpz6acgp4.cloudfront.net/images/landing_bg_mobile.webp',

  navGames: 'https://d208znpz6acgp4.cloudfront.net/images/nav_games.svg',
  navGamesInactive:
    'https://d208znpz6acgp4.cloudfront.net/images/nav_games_inactive.svg',

  navDropbox: 'https://d208znpz6acgp4.cloudfront.net/images/nav_dropbox.webp',
  navDropboxInactive:
    'https://d208znpz6acgp4.cloudfront.net/images/nav_dropboxes_inactive.webp',

  navTournaments:
    'https://d208znpz6acgp4.cloudfront.net/images/nav_tournaments.svg',
  navTournamentsInactive:
    'https://d208znpz6acgp4.cloudfront.net/images/nav_tournaments_inactive.svg',

  navRatings: 'https://d208znpz6acgp4.cloudfront.net/images/nav_ratings.svg',
  navRatingsInactive:
    'https://d208znpz6acgp4.cloudfront.net/images/nav_ratings_inactive.svg',

  navGiveaways:
    'https://d208znpz6acgp4.cloudfront.net/images/nav_giveaways.svg',
  navGiveawaysInactive:
    'https://d208znpz6acgp4.cloudfront.net/images/nav_giveaways_inactive.svg',

  boostDepEvent:
    'https://d208znpz6acgp4.cloudfront.net/images/boost_dep_event.webp',
  keyDepEvent:
    'https://d208znpz6acgp4.cloudfront.net/images/key_dep_event.webp',

  flagRu: 'https://d208znpz6acgp4.cloudfront.net/images/flag_ru.svg',
  flagUa: 'https://d208znpz6acgp4.cloudfront.net/images/flag_ua.svg',
  flagUs: 'https://d208znpz6acgp4.cloudfront.net/images/flag_us.svg',

  tgNav: 'https://d208znpz6acgp4.cloudfront.net/images/tg_nav.svg',
  ttNav: 'https://d208znpz6acgp4.cloudfront.net/images/tt_nav.svg',
  instNav: 'https://d208znpz6acgp4.cloudfront.net/images/inst_nav.svg',
  logoutNav: 'https://d208znpz6acgp4.cloudfront.net/images/logout_nav.svg',
  discordNav: 'https://d208znpz6acgp4.cloudfront.net/images/discord_nav.svg',
  giveawayIcon:
    'https://d208znpz6acgp4.cloudfront.net/images/giveaway_icon.svg',

  itemsNav: 'https://d208znpz6acgp4.cloudfront.net/images/items_nav.svg',
  itemsNavActive:
    'https://d208znpz6acgp4.cloudfront.net/images/items_nav_active.svg',
  playNav: 'https://d208znpz6acgp4.cloudfront.net/images/play_nav.svg',
  playNavActive:
    'https://d208znpz6acgp4.cloudfront.net/images/play_nav_active.svg',
  ratingsNav: 'https://d208znpz6acgp4.cloudfront.net/images/ratings_nav.svg',
  ratingsNavActive:
    'https://d208znpz6acgp4.cloudfront.net/images/ratings_nav_active.svg',
  vipNav: 'https://d208znpz6acgp4.cloudfront.net/images/vip_nav.svg',
  vipNavActive:
    'https://d208znpz6acgp4.cloudfront.net/images/vip_nav_active.svg',
  refsNav: 'https://d208znpz6acgp4.cloudfront.net/images/refs_nav.svg',
  refsNavActive:
    'https://d208znpz6acgp4.cloudfront.net/images/refs_nav_active.svg',
  shopNav: 'https://d208znpz6acgp4.cloudfront.net/images/shop_nav.svg',
  shopNavActive:
    'https://d208znpz6acgp4.cloudfront.net/images/shop_nav_active.svg',
  tasksNav: 'https://d208znpz6acgp4.cloudfront.net/images/tasks_nav.svg',
  tasksNavActive:
    'https://d208znpz6acgp4.cloudfront.net/images/tasks_nav_active.svg',
  tournamentNav:
    'https://d208znpz6acgp4.cloudfront.net/images/tournament_nav.svg',
  tournamentNavActive:
    'https://d208znpz6acgp4.cloudfront.net/images/tournament_nav_active.svg',
};

export default urls;
