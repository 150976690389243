import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  FooterNavigation,
  GoldCup,
  Header,
  Information,
  Level,
  RollCircle,
  RoundClearedModal,
  WellcomeScreen,
  LevelClearedModal,
} from "../../components";

import api from "../../api";

import styles from "./styles.module.css";
import { refetchProfile } from "../../redux/actions/Account";

export function MainPage() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [gameConfig, setGameConfig] = useState({});
  const [levelConfig, setLevelConfig] = useState({});
  const [rewardReceived, setRewardReceived] = useState(0);
  const [curProgress, setCurProgress] = useState(0);
  const [unclearedRounds, setUnclearedRounds] = useState(true);
  const [shootingTarget, setShootingTarget] = useState(null);
  const [totalTargets, setTotalTargets] = useState(0);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLevelModalOpen, setIsLevelModalOpen] = useState(false);
  console.log(userData);
  const circleAmount = Array(userData?.level).length;

  const getUserSummaryHandler = () =>
    api.getUserSummary().then(({ data }) => {
      console.log(data, "getUserSummaryHandler");
      setUserData(data);
      const [curProgress] = data?.progress.filter(
        (r) =>
          r?.cycle == data?.cycle &&
          r?.level == data?.level &&
          ((!r.claimed && r.completed) || !r.completed)
      );
      setCurProgress(curProgress);

      // console.log(data?.cycle);
      // console.log(data?.level);
      // // console.log(data?.progress.filter((r) => console.log(r.cycle)));

      setUnclearedRounds(
        data?.progress?.filter(
          (r) =>
            r?.cycle == data?.cycle &&
            r?.level == data?.level &&
            (!r.claimed || !r.completed)
        )
      );

      let calculatedTargets = 0;

      const curLvlRounds = data?.progress.filter(
        (p) => p?.cycle == data?.cycle && p?.level == data?.level
      );

      curLvlRounds.forEach((r) => (calculatedTargets += r?.targets?.length));

      setTotalTargets(calculatedTargets);
    });

  const getGameConfigHandler = () => {
    try {
      api.getGameConfig().then((response) => {
        if (response.status === 200) {
          setGameConfig(response?.data);
        }
      });
    } catch (error) {
      console.error(`Error message ${error}`);
    }
  };

  const getLevelConfigHandler = () =>
    api.getLevelConfig().then(({ data }) => {
      // console.log(data);
      setLevelConfig(data[0]);
    });

  useEffect(() => {
    getUserSummaryHandler();
  }, []);

  useEffect(() => {
    getGameConfigHandler();
  }, []);

  useEffect(() => {
    getLevelConfigHandler();
  }, []);

  const select = (ind) => {
    setShootingTarget(ind);

    api
      .shootTarget(ind)
      .then(({ data }) => {
        console.log(data, "shootTarget");
      })
      .then(() => {
        getUserSummaryHandler();
        getGameConfigHandler();
        dispatch(refetchProfile());
      });
  };

  const claimRound = () => {
    try {
      api
        .claimRound()
        .then((response) => {
          console.log(response?.data, "claimRound");
        })
        .then(() => {
          getUserSummaryHandler();
          getGameConfigHandler();
        });
    } catch (error) {
      console.error(`Error message: ${error}`);
    }
  };

  const claimLevel = () => {
    try {
      api.claimLevel().then((response) => {
        if (response.status === 200) getUserSummaryHandler();
        getGameConfigHandler();
      });
    } catch (error) {
      console.error(`Error message: ${error}`);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoaded(true);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (!isLoaded) {
    return <WellcomeScreen />;
  }

  console.log(unclearedRounds, "unclearedRounds.length");
  return (
    <>
      <div className={styles.wrapper}>
        <Header gameConfig={gameConfig} />

        <section className={styles.firstSection}>
          <Level />
          <GoldCup />
        </section>

        <section className={styles.secondSection}>
          <div className={circleAmount > 1 ? styles.rollContainerSM : null}>
            {Array(userData?.level)
              .fill(1)
              .map((_, i) => (
                <RollCircle
                  key={i}
                  circleIndex={i}
                  select={select}
                  curProgress={curProgress}
                  circleAmount={circleAmount}
                />
              ))}
          </div>
          <div className={styles.informationContainer}>
            <Information />
            <Information bullets={true} gameConfig={gameConfig} />
          </div>
          <div className={styles.footerContainer}>
            <FooterNavigation />
          </div>
        </section>

        <RoundClearedModal
          claimRound={claimRound}
          isOpen={curProgress?.completed && !curProgress?.claimed}
          curProgress={curProgress}
        />
        <LevelClearedModal
          isOpen={!unclearedRounds.length}
          levelConfig={levelConfig}
          totalTargets={totalTargets}
          claimLevel={claimLevel}
        />
      </div>
    </>
  );
}

{
  /* <div
          style={{
            background: "#000",
            display: "flex",
            justifyContent: "space-between",
            color: "#fff",
            padding: "20px",
          }}
        >
          <div>Bullets per hour: {gameConfig?.bullets[0]?.value}</div>
  
          <div>Balance: {userData?.bonusBalance || 0}</div>
        </div>
  
        <div className={styles.appHeader}>
          <div className={styles.levelBlock}>
            Lvl {userData?.level} <br />
            {levelConfig?.rounds_to_complete == userData?.round
              ? "Final round"
              : `Round ${userData?.round}`}
          </div>
  
          <div className={styles.goHolder}>
            {Array(userData?.level)
              .fill(1)
              .map((key, ind) => (
                <div
                  key={ind}
                  style={{
                    cursor: "pointer",
                    background: "#000",
                    borderRadius: "50%",
                    width: "100px",
                    minWidth: "100px",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => select(ind + 1)}
                >
                  {curProgress?.targets?.indexOf(ind + 1) >= 0 ? (
                    <div>
                      {curProgress?.prizes.filter((p) => p.targetId == ind + 1)
                        .length
                        ? curProgress?.prizes.filter(
                            (p) => p.targetId == ind + 1
                          )[0].title
                        : "😓"}
                    </div>
                  ) : (
                    "GO"
                  )}
                </div>
              ))}
          </div>
  
          <div className={styles.rewardBlock}>
            Rewards {curProgress?.prizes?.length}/{userData?.lootTierLvl || 1}
          </div>
        </div>
  
        {curProgress?.completed && !curProgress?.claimed && (
          <div className={styles.roundEnd}>
            ROUND CLEARED
            {curProgress?.prizes.map((pr) => (
              <div>{pr.title}</div>
            ))}
            <div
              style={{
                background: "green",
                padding: "10px",
                borderRadius: "5px",
                width: "100px",
                margin: "0 auto",
              }}
              onClick={claimRound}
            >
              CLAIM
            </div>
          </div>
        )}
  
        {!unclearedRounds.length && (
          <div className={styles.roundEnd}>
            LEVEL CLEARED
            <div>Lvl reward: {levelConfig.lvl_bonus}</div>
            <div>
              Accuracy bonus: +
              {parseInt(
                levelConfig.accuracy_bonus *
                  (+levelConfig.rounds_to_complete / +totalTargets)
              )}{" "}
              ({levelConfig.accuracy_bonus}*
              {parseInt((+levelConfig.rounds_to_complete / +totalTargets) * 100)}
              %)
            </div>
            <div>Upgrade bonus: +0</div>
            <div
              style={{
                background: "green",
                padding: "10px",
                borderRadius: "5px",
                width: "100px",
                margin: "0 auto",
              }}
              onClick={claimLevel}
            >
              CLAIM
            </div>
          </div>
        )}
  
        <div
          style={{
            background: "#000",
            display: "flex",
            justifyContent: "space-between",
            color: "#fff",
            padding: "20px",
          }}
        >
          <div>Shot consumption: {gameConfig?.gun[0]?.value}</div>
  
          <div>
            Bullets: {userData?.curBullets || 0}/{gameConfig?.ammo[0]?.value}
          </div>
        </div> */
}
