import { axiosInstance } from '../axios';

const api = {
  getUserSummary() {
    return axiosInstance({
      method: 'get',
      url: '/player',
    });
  },

  claimRound() {
    return axiosInstance({
      method: 'get',
      url: '/player/claim-round',
    });
  },

  claimLevel() {
    return axiosInstance({
      method: 'get',
      url: '/player/claim-level',
    });
  },

  getGameConfig() {
    return axiosInstance({
      method: 'get',
      url: '/game/config',
    });
  },

  getLevelConfig() {
    return axiosInstance({
      method: 'get',
      url: '/game/level',
    });
  },

  shootTarget(data) {
    return axiosInstance({
      method: 'post',
      url: '/game/shoot',
      data: {
        target: data
      }
    });
  },

  updatePlayerData(data) {
    return axiosInstance({
      method: 'put',
      url: '/player/update',
      data
    });
  },
  promoCodeValidation(data) {
    return axiosInstance({
      method: 'post',
      url: '/player/use-promo',
      data
    });
  }
};


export default api;
