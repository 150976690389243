import React from "react";

import goldcup from "../../assets/goldcup.svg";

export const GoldCup = () => {
  return (
    <div
      style={{
        paddingRight: "18px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <span
        style={{
          color: "#696969",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: "300",
        }}
      >
        # 3109
      </span>
      <img src={goldcup} alt="" />
    </div>
  );
};
