import React from "react";
import { Link } from "react-router-dom";

import telegram from "../../assets/socials/telegram.svg";
import discord from "../../assets/socials/discord.svg";
import instagram from "../../assets/socials/instagram.svg";

const data = [
  { icon: telegram, url: "https://t.me/youwingg" },
  { icon: discord, url: "https://discord.com/invite/rgG9Zmw9" },
  {
    icon: instagram,
    url: "https://www.instagram.com/youwin.gg?igsh=NDQ5ejZ4anhrNG5l",
  },
];

export const Socials = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "32px" }}>
      {data.map((item, i) => (
        <Link key={i} to={item.url}>
          <img src={item.icon} alt="" />
        </Link>
      ))}
    </div>
  );
};
