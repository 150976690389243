import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.css";

export const LinksButton = ({ item }) => {
  const navigate = useNavigate();
  return (
    <button className={styles.link} onClick={() => navigate(item.path)}>
      <img
        style={{ width: `${item.width}px`, height: `${item.height}px` }}
        src={item.icon}
        alt=""
      />
      <span className={styles.title}>{item.title}</span>
    </button>
  );
};
