import React from "react";
import styles from "./styles.module.css";

export const CsIsLiveNow = () => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.cs2}>CS2 </span>
      PLATFORM IS{" "}
      <span className={styles.cs2} style={{ color: "#00E890" }}>
        LIVE
      </span>{" "}
      NOW!
    </div>
  );
};
