import { useEffect, useState } from "react";
import HeaderBlocks from "./HeaderBlocks/HeaderBlocks";
import api from "../../api";
import { useSelector } from "react-redux";

export const Header = ({ gameConfig }) => {
  const { user } = useSelector(({ account }) => account);

  const userBulletsPerHour = gameConfig?.bullets[0]?.value || 0;
  return (
    <header
      style={{
        padding: "0px 0 10px 0",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <HeaderBlocks position={"left"} userBulletsPerHour={userBulletsPerHour} />
      <HeaderBlocks bonusBalance={user?.data?.bonusBalance} />
    </header>
  );
};
