/* eslint-disable no-param-reassign */
import {
  UPDATE_PROFILE,
  LOGIN_SUCCESS,
  SILENT_LOGIN,
  LOGOUT,
} from "../constants/actionTypes";

const initialState = {
  user: null,
};

const Account = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGOUT:
      return { user: null };

    case SILENT_LOGIN:
    case LOGIN_SUCCESS:
      return { ...payload };

    case UPDATE_PROFILE:
      return { user: { ...state.user, data: payload } };

    default:
      return state;
  }
};

export default Account;
